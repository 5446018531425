import React from 'react';
import { Helmet } from 'react-helmet'
import { main } from './404.module.css';
import Logo from '../components/Logo';

export default () => (
    <div className={main}>
        <Helmet>
            <title>Flash Moves - Page Not Found</title>
        </Helmet>
        <Logo orientation="vertical" fill="colored" />
        <h1>404 Page Not Found</h1>
    </div>
);
